import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CreatureService } from '../services/creature-service';


@Component({
  selector: 'app-dialog-creature',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, RouterLink],
  templateUrl: './dialog-creature.component.html',
  styleUrls: ['./dialog-creature.component.scss']
})


export class DialogCreatureComponent {

  dialogRef = inject(MatDialogRef);
  cs = inject(CreatureService);
  
  creature = this.cs.creature;
  foundCreature = this.cs.foundCreature;
  
  constructor() { 
    this.dialogRef.disableClose = true;

    // this.dialogRef.afterClosed().subscribe(result => {
    //   console.log(`Dialog result: ${result}`);
    // });
    

  }




}
