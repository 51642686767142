import { Timestamp } from "@angular/fire/firestore"

export interface Lesson {
    id: string;
    title?: string;
    content: string;
    vocabulary?: string[];
    language: string;
    languageCode?: string;
    tags?: string[];
    date?: Timestamp;
    ownerID?: string | null | undefined;
    private?: boolean;
    imageUrl?: string;
    audioUrl?: string;
}

export const INIT_LESSON: Lesson =
{
    id: "initial",
    title: "",
    content: "Open a lesson from the table or click the blue box to add your own text.",
    language: ""
}

export const INIT_LESSON_LOADING: Lesson =
{
    id: "abc",
    title: "Loading...",
    content: "Loading...",
    language: "Loading..."
}