import { Creature } from "./creature";
import { History } from "./history";
import { Lesson } from "./lesson";

export interface Profile {
    nickName: string;
    linesRead: number;
    history: History[];
    sevenDayAvg: number;
    thirtyDayAvg: number;
    dailyLinesRead: [
        { date: string, linesRead: number }
    ];
    lessons: Lesson[];
    creatures: Creature[];
    email: string;
    userName : string;
}

export const INIT_PROFILE: Profile = {
    "nickName": "",
    "linesRead": 0,
    "history": [],
    "sevenDayAvg": 0,
    "thirtyDayAvg": 0, 
    "dailyLinesRead": [{ date: new Date().toISOString(), linesRead: 0 }], 
    "lessons": [], 
    "creatures": [], // add this property to fix the error
    "email": "",
    "userName": ""
  };