import { ActivatedRoute } from '@angular/router';
import { assignLanguageCode } from '../shared/utils';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { INIT_LESSON, INIT_LESSON_LOADING, Lesson } from '../shared/lesson';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Profile, INIT_PROFILE } from '../shared/profile';
import { Router } from '@angular/router';
import {
  arrayRemove, arrayUnion, doc, docData, DocumentData, DocumentReference, Firestore, getDoc, setDoc, Timestamp, updateDoc, 
} from '@angular/fire/firestore';
import { Observable, shareReplay } from 'rxjs';
import { Creature, INIT_CREATURE } from '../shared/creature';




@Injectable({
  providedIn: 'root'
})
export class DbService {

  db = inject(Firestore);
  authService = inject(AuthService);
  router = inject(Router);
  http = inject(HttpClient);
  route = inject(ActivatedRoute);

  userEmail = signal<string>("none");
  isNewUser = this.authService.isNewUser;
  userID: string = "public";
  #userProfileRef: DocumentReference<DocumentData> = doc(this.db, "non-users", "public");
  #creatureRef: DocumentReference<DocumentData> = doc(this.db, "creatures", "creatures");

  userProfile = signal<Profile>(INIT_PROFILE);
  reloadLessons = signal<boolean>(false);
  publicLessons$: Observable<Lesson[]> = new Observable<Lesson[]>();

  profile$: Observable<Profile> = new Observable<Profile>();

  lessonLanguage = signal<string | undefined>(undefined);
  currentLinesRead = signal<number>(0);

  foundCreature = signal<boolean>(false);
  creature: Creature = INIT_CREATURE;

  lessonHistory = computed(() => {
    return this.userProfile().history;
  });

  lesson = signal<Lesson>(INIT_LESSON);
  filePath = signal<string>('');
  audioPath = signal<string>('');
  gotStats = false;

  themeColors = signal<string[]>(["inherit", "inherit"]);
  
  constructor() {
    // if (this.authService.isLoggedIn) {
    this.profile$ = this.authService.getUser().pipe(
      switchMap(user => {
        if (user) {
          this.userID = user.uid;
          this.userEmail.set(user.email || "none");
          this.#userProfileRef = doc(this.db, "users", user.uid);
          return docData(this.#userProfileRef) as Observable<Profile>;
        } else {
          return new Observable<Profile>();
        }
      }),
      shareReplay(1)
    );

    // this.#lessonsbyUser$ = this.profile$.pipe(
    //   distinctUntilChanged((prev, curr) => prev.lessons.length == curr.lessons.length),
    //   map(profile => profile.lessons),
    //   shareReplay(1)
    // );
    // }


  }

  get lessonsbyUser$() {
    return this.profile$.pipe(
      map(profile => profile.lessons));
  }


  async createUserProfile() {

    const docSnap = await getDoc(this.#userProfileRef);
    if (!docSnap.exists()) {
      console.log("creating user profile");
      setDoc(this.#userProfileRef, { ...INIT_PROFILE, email: this.userEmail() });
      this.isNewUser.set(false);
    }
    if (docSnap.exists()) {
      console.log("user profile exists");
      updateDoc(this.#userProfileRef, { ...INIT_PROFILE, ...docSnap.data(), email: this.userEmail() });
      this.isNewUser.set(false);
    }
  };

  get userProfileRef() {
    return this.#userProfileRef;
  }

  get creaturesRef() {
    return this.#creatureRef;
  }

  getJSON(filename: string): Observable<Lesson[]> {
    return this.http.get<any[]>('../../assets/lessons/' + filename + '.json').pipe(
      startWith([INIT_LESSON_LOADING]),
      map((lessons) => lessons.map((lesson: any) => {
        lesson.date = new Timestamp(lesson.date?.seconds || 1, lesson.date?.nanoseconds || 1);
        lesson.title = lesson.title || "Untitled";
        lesson.content = lesson.content || "No Content";
        lesson.language = lesson.language || "none";
        lesson.tags = lesson.tags || [];
        lesson.private = lesson.private || false;
        lesson.id = lesson.id || "none";
        lesson.vocabulary = lesson.vocabulary || [];
        return lesson as Lesson;
      }))
    );

  }

  getLessons(language: string): Observable<Lesson[]> {
    language = language.toLocaleLowerCase();
    const lessonsRef = doc(this.db, "lessons", language);
    return docData(lessonsRef).pipe(
      map((doc) => doc ? doc['lessons']: undefined),
      shareReplay(1))
  }

  // getFable(): Observable<Lesson> {
  //   return this.http.get<Story>('https://shortstories-api.onrender.com/').pipe(
  //     map((doc) => ({ id: 'fable-' + doc._id, title: doc.title, content: doc.story, language: 'English', ownerID: "public" }) as Lesson)
  //   )
  // }


  async addLesson(lesson: Lesson): Promise<string> {
    const lang = lesson.language.toLowerCase();
    const newId = self.crypto.randomUUID();
    lesson.id = newId;
    //allows admin to add lessons for everyone
    if (lesson.ownerID === 'public') {
      const docRef = doc(this.db, "lessons", lang);
      console.log("lang", lesson.language);
      await updateDoc(docRef, { lessons: arrayUnion(lesson) });
      return lesson.id;
    }
    //allows users to add lessons for themselves
    else {
      const docRef = this.#userProfileRef;
      this.userProfile.update(profile => {
        let updatedProfile = {...profile};
        updatedProfile.lessons.push({ ...lesson });
        return updatedProfile;
      });
      await updateDoc(docRef, { lessons: arrayUnion(lesson) });
      this.lesson.set({ ...lesson });
      // this.router.navigate(['user', lesson.id], { queryParams: lesson.private ? { 'publiclink': 'no' } : { 'publiclink': 'yes' } });
      return lesson.id;
    }
  }

  async updateLesson(lesson: Lesson) {
    const lang = lesson.language.toLowerCase();
    //allows admin to update lessons for everyone
    if (lesson.ownerID === 'public') {
      const docRef = doc(this.db, "lessons", lang);
      const snapshot = await getDoc(docRef);
      if (snapshot.exists()) {
        const lessonToRemove = snapshot.data()['lessons'].find((l: Lesson) => l.id === lesson.id) || null;
        await updateDoc(docRef, { lessons: arrayRemove(lessonToRemove) });
        await updateDoc(docRef, { lessons: arrayUnion(lesson) });
        return;
      } else {
        console.error("Lesson not found");
        return;
      }
      //allows users to update lessons for themselves
    } else if (this.userProfile().lessons.length > 0) {
      for (let i = 0; i < this.userProfile().lessons.length; i++) {
        if (this.userProfile().lessons[i].id === lesson.id) {
          this.userProfile.update(profile => {
            let updatedProfile = {...profile};
            updatedProfile.lessons[i] = { ...lesson };
            return {...updatedProfile};
          });
        }
      }
      const snapshot = await getDoc(this.#userProfileRef);
      if (!snapshot.exists()) {
        console.error("User not found");
        return;
      } else {
        const lessonToRemove = snapshot.data()['lessons'].find((l: Lesson) => l.id === lesson.id) || null;
        if (lessonToRemove) {
          updateDoc(this.#userProfileRef, { lessons: arrayRemove(lessonToRemove) });
          updateDoc(this.#userProfileRef, { lessons: arrayUnion(lesson) });
          this.lesson.set({ ...lesson });
          return;
        }
        else {
          console.error("Lesson not found");
          return;
        }
      }
    }
  }

  async removeLesson(lesson: Lesson) {
    const lang = lesson.language.toLowerCase();
    //allows admin to remove lessons for everyone
    if (lesson.ownerID === 'public') {
      const docSnap = await getDoc(doc(this.db, "lessons", lang));
      if (docSnap.exists()) {
        const lessonToRemove = docSnap.data()['lessons'].find((l: Lesson) => l.id === lesson.id) || null;
        if (lessonToRemove) {
          await updateDoc(doc(this.db, "lessons", lang), { lessons: arrayRemove(lessonToRemove) });
          // this.lesson.set(INIT_LESSON);
          return;
        }
      }
    } else
      //allows users to remove lessons for themselves
      if (lesson.ownerID !== 'public') {
        let newArray = this.userProfile().lessons;
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i].id === lesson.id) {
            newArray.splice(i, 1);
          }
        }
        if (!newArray) { console.error("Lesson not found"); return; }
        if (newArray) {
          this.userProfile.update(profile => {
            let updatedProfile = {...profile};
            updatedProfile.lessons = [...newArray];
            return updatedProfile;
          });
        };
        const docSnap = await getDoc(this.#userProfileRef);
        if (docSnap.exists()) {
          const lessonToRemove = docSnap.data()['lessons'].find((l: Lesson) => l.id === lesson.id) || null;
          if (lessonToRemove) {
            await updateDoc(this.#userProfileRef, { lessons: arrayRemove(lessonToRemove) });
            // this.lesson.set(INIT_LESSON);
            return;
          }
          else {
            console.error("Lesson not found");
            return;
          }
        }
      }
  }

  //basic load from URL before login
  loadLesson(lesson: Lesson) {
    if (!lesson.languageCode) {
      let combined = assignLanguageCode(lesson.language);
      if (combined) {
        lesson.languageCode = combined.code || "en";
        lesson.language = combined.name || "English";
      }
    }
    this.lessonLanguage.set(lesson.languageCode || "en-CA");
    if (this.userEmail() !== "none") {
    this.updateLessonHistory(lesson);
    }
    this.lesson.set({ ...lesson });
    return this.lesson();
  }

  //load from lessons table edit button
  async pushLesson(lessonPassed: Lesson, publicLesson: boolean = false) {
    console.log("passedLesson", lessonPassed, publicLesson);
    const lesson = await this.loadLesson(lessonPassed);
    let lang = lesson.language.toLowerCase();
    if (lesson.ownerID !== 'public') lang = 'user';
    this.router.navigate([lang, lesson.id], { queryParams: publicLesson ? { 'publiclink': 'yes' } : { 'publiclink': 'no' } });
    // this.updateLessonHistory(lesson);
    // if (this.userEmail() !== "none") {
    //   this.saveStats();
    // }
  }

  updateLessonHistory(lesson: Lesson) {
    this.userProfile.update(profile => {
      let updatedProfile = {...profile};
      if(updatedProfile.history[0]?.id === lesson.id) return updatedProfile;
      updatedProfile.history.unshift({ id: lesson.id, title: lesson.title || "Untitled", language: lesson.language.toLowerCase() });
      if (updatedProfile.history.length > 10) updatedProfile.history.splice(10);
      return updatedProfile;
    });
  }

  updateNickname(nickname: string) {
    this.userProfile.update(profile => {
      let updatedProfile = {...profile};
      updatedProfile.nickName = nickname;
      updateDoc(this.#userProfileRef, { nickName: nickname });
      return updatedProfile;
    });
  }
}



