<label>Toggle Translations
    <input label="translate tiles" type="checkbox"  (change)="toggleTranslations()">
</label>
    
<ul class="card-container" *ngIf="playing">
    <li *ngFor="let word of shuffledList; let i = index">
    <ng-container *ngIf="word[2] === 'translated' else original">
        <div class="card translatedTile" [lang]="lesson().languageCode" translate="yes" (click)="clicked(word[1], $event)">{{word[0]}}</div>
    </ng-container>
    <ng-template #original>
        <div class="card" [lang]="lesson().languageCode" translate="no" (click)="clicked(word[1], $event)">{{word[0]}}</div>
    </ng-template>
    </li>
</ul>


<!-- <div class="flex-row">
    <button class="green" #againButton type="button" (click)="playAgain()">Play Again</button>
</div> -->
          
            

