<h2 mat-dialog-title>YOU FOUND A CREATURE!</h2>
<mat-dialog-content class="mat-typography">
  <div class="dialog">
    <h3 translate="no">{{creature.name | uppercase}}</h3>

    <ng-container *ngIf="creature.video; else jpeg">
      <video autoplay loop muted>
        <source src="../../assets/creatures/{{creature.video}}" type="video/mp4">
      </video>
    </ng-container>

    <ng-template #jpeg>
      <img  mat-card-image src="{{creature.image}}" alt="creature thumbnail">
    </ng-template>
    <!-- <img mat-card-image src="{{creature.image}}" alt="Photo of a {{creature.name | uppercase}}"> -->
    <p>{{creature.description}}</p>
    <p *ngIf="creature.frequency=='common'">This creature is common. They are the easiest to find.</p>
    <p *ngIf="creature.frequency=='uncommon'">This creature is uncommon. They are harder to find than common creatures.
    </p>
    <p *ngIf="creature.frequency=='rare'">This creature is rare. You must be working hard!</p>
    <p *ngIf="creature.frequency=='legendary'">This creature is legendary. They are the hardest to find.</p>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close routerLink="profile">View Your Collection</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions>