<div class="header withShadow container" translate="no">
    <mat-toolbar class="navbar">
        <li mat-button [matMenuTriggerFor]="menu2" class="menu-icon" aria-label="menu icon">
            <img class="symbol lg invert" alt="menu" src="../../assets/images/menu-svgrepo-com.svg" />
        </li>
        <li >
            <span class="header" routerLink="/home/">Level Up Your Reading</span>
        </li>

        <li routerLinkActive="active" *ngIf="!(isLoggedIn | async)">
            <a routerLink="auth">Login</a>
        </li>

        <li mat-button class="" *ngIf="isLoggedIn | async" (click)="saveAndGo('profile')">
            <img class="symbol lg invert icon-button" src="../../assets/images/user.svg" alt="">
        </li>


    </mat-toolbar>
    <mat-toolbar-row class="navbar second">
        <li routerLinkActive="active">
            <a mat-button (click)="saveAndGo('/user')">My Lessons</a>
        </li>
        <li routerLinkActive="active">
            <a mat-button routerLink='/english' (click)="saveAndGo('/english')">English</a>
        </li>
        <li routerLinkActive="active">
            <a mat-button routerLink='/german' (click)="saveAndGo('/german')">German</a>
        </li>
        <!-- <li> <a mat-button [matMenuTriggerFor]="menu2">More</a>
        </li> -->
    </mat-toolbar-row>
    <mat-menu #menu2="matMenu" translate="no">
        <a mat-menu-item class="menu-item" routerLink="/">Home</a>
        <a mat-menu-item class="menu-item" href="https://blog.teacherjake.com" rel="noopener noreferrer">Blog</a>
        <a mat-menu-item class="menu-item" href="https://tips.teacherjake.com/privacy-policy"
            rel="noopener noreferrer">Privacy</a>
        <hr>
        <button (click)="saveAndGo('arabic')" mat-menu-item>Arabic</button>
        <button (click)="saveAndGo('french')" mat-menu-item>French</button>
        <button (click)="saveAndGo('spanish')" mat-menu-item>Spanish</button>
        <button (click)="saveAndGo('thai')" mat-menu-item>Thai</button>
    </mat-menu>
</div>


<!-- <mat-menu #menu="matMenu" translate="no">
    <button routerLink="/" mat-menu-item>Welcome</button>
    <button routerLink="/auth" mat-menu-item *ngIf="!(isLoggedIn | async)"> Login</button>
    <button routerLink="/profile" mat-menu-item *ngIf="isLoggedIn | async">Profile</button>
    <button routerLink="/user" mat-menu-item>My Lessons</button>
    <hr>
    <a mat-menu-item class="menu-item" href="https://blog.teacherjake.com" rel="noopener noreferrer">Blog</a>
    <a mat-menu-item class="menu-item" href="https://tips.teacherjake.com" rel="noopener noreferrer">Theory</a>
    <a mat-menu-item class="menu-item" href="https://tips.teacherjake.com/privacy-policy" rel="noopener noreferrer">Privacy</a>
</mat-menu> -->