<section translate="yes">
    <div class="withShadow withMarginBottom rounded-box bg1 center">
        <h3>Learn to Read Line-by-Line</h3>
        <p>Reading is a great way to learn new vocabulary and to get used the grammar of a new language. Translations are available to help beginners get a rough understanding of what is being said, but don't worry about mastering the lesson in one go. Just try to get the gist of it at first.  More and more of the meaning will fall into place as you repeat lessons and come back to them after reading other lessons.</p>
        <ul>
            <li>To get the most out of this app, you need to use a web browser that supports text-to-speech. Microsoft Edge and Google Chrome have very natural
            sounding voices for most languages. If you are using an iOS device, you may need to install a language pack under settings.</li>
        <li>Need more texts? Try asking an AI for a short conversation between two people in the language you want to learn. Then, click the blue box and paste it into the reading tool. You can save it to study later too.</li>
        <li>Once you are ready for longer texts or videos, I recommend checking out LingQ and LanguageCrush for reading and Language Reactor for videos. You can also start practicing a little writing with Character AI.</li> 
        <li>Most of the texts are AI generated. English texts have been reviewed or edited by a native English speaker.</li>
        </ul>
        <p>If you have any questions or would like to see more of a certain type of content, feel free to contact me at: admin&#64;teacherjake.com.</p>
        <p>Enjoy!</p>
        <p>Teacher Jake</p>
        <p>P.S. If you'd like to submit content written in your native language, please email some samples and I can make you a contributor. </p>
        <button type="button"><a routerLink="/english/1">Start Learning!</a></button>
    </div>
    <div class="withShadow withMarginBottom bg1 rounded-box withGreen" *ngIf="!(this.isLoggedIn | async)">
    <app-login></app-login>
    </div>

</section>