import { Component, OnInit, effect, inject } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { RouterOutlet } from '@angular/router';
import { DbService } from './services/db.service';
import { SEOServiceService } from './services/seoservice.service';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, CommonModule]
})
export class AppComponent implements OnInit {
  title = 'TJ - Level Up';
  db = inject(DbService);
  seoService = inject(SEOServiceService);

  lesson = this.db.lesson;
  defaultImage = "../assets/images/level-up-banner.jpeg";

  // themeColors = this.db.themeColors;
  // backgroundColor = this.themeColors()[0];
  // textColor = this.themeColors()[1];
  // fsize = this.db.fontSize;
  
  constructor() {
    effect(() => {
      this.lesson();
      let title = this.lesson().title || 'Stories';
      this.seoService.updateOgImage(this.lesson().imageUrl || this.defaultImage);
      this.seoService.updateTitle(title);
      this.seoService.updateDescription(`Click to free line-by-line audio and translations for "${title}" and more.`);
    });

    // effect(() => {
    //   this.themeColors();
    //   this.backgroundColor = this.themeColors()[0];
    //   this.textColor = this.themeColors()[1];
    // });

  }

  ngOnInit() {
    this.seoService.updateOgImage(this.lesson().imageUrl || this.defaultImage)
  }

}
