import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-warning-tts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './warning-tts.component.html',
  styleUrls: ['./warning-tts.component.scss']
})
export class WarningTtsComponent implements OnInit {

  thisURL: string = "";
  ttsSupported: boolean = false;  

  ngOnInit(): void {
    const window = document.defaultView;
    (window) ? this.thisURL = window.location.href : "";

    if (window && 'speechSynthesis' in window) {
      this.ttsSupported = true;
    }
  }


  copyUrl(data: string): void {
    navigator.clipboard.writeText(data)
    .then(() => {
      alert("successfully copied");
    })
  } 

}
