<div *ngIf="!(auth.authState$ | async)" class="withMarginBottom">
  <p class="center">Note: You need to login to save your listening points and to create lessons.</p>
  <app-warning-tts></app-warning-tts>
  @if (!useEmail) {
    <button mat-raised-button (click)="loginEmail()">
      <mat-icon>email</mat-icon>
      <p>Login with Email</p>
    </button>
  }
  @else {
    <div class="withMarginBottom">
      <app-email></app-email>
    </div>
  }
  <button mat-raised-button (click)="loginGoogle()">
    <img alt="google logo" src="../../assets/google-logo.svg" />
    <p>Login with Google</p>
  </button>


</div>

<div *ngIf="auth.authState$ | async as user" class="logout">
  <p>
    Logged in as: <strong>{{ user.email }} ({{userProfile().nickName}})</strong>
  </p>

  <button mat-stroked-button (click)="logout()">Logout</button>
</div>