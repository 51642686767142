import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatCardModule} from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from 'src/app/services/auth.service';
import { tap } from 'rxjs';


@Component({
  selector: 'app-email',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatInputModule, MatButtonModule, MatFormFieldModule, ReactiveFormsModule],
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  constructor(private fb: FormBuilder, private auth: AuthService) {}
  
  form: FormGroup = new FormGroup ({});
  type: 'login' | 'signup' | 'reset' = 'login';
  loading=false;
  serverMessage: string  | null = null;
  isError$ = this.auth.error$;
  isNewUser = this.auth.isNewUser;

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.minLength(6), Validators.required]
      ],
      passwordConfirm: ['', []]
    });

    this.isError$.pipe(
      tap(error => this.serverMessage = error)
    );

  }

  changeType(val: 'login' | 'signup' | 'reset' = 'signup') {
    this.type = val;
  }

  dismissError() {
    this.auth.clearError();
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }

  get isSignup() {
    return this.type === 'signup';
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get passwordConfirm() {
    return this.form.get('passwordConfirm');
  }

  get passwordDoesMatch() {
    if (this.type !== 'signup') {
      return true;
    } else {
      return this.password?.value === this.passwordConfirm?.value;
    }
  }

async onSubmit() {
  this.loading = true;

  const email = this.email?.value;
  const password = this.password?.value;

  try {
    if (this.isLogin) {
      this.auth.signInEmail(email, password);
    }
    if (this.isSignup) {
      this.auth.createUserWithEmail(email, password);
    }
    if (this.isPasswordReset) {
      this.auth.sendReset(email);
      this.serverMessage = 'Check your email';
    }
  } catch (err) {
    console.log(err);
    // this.serverMessage = new Error(err || "OOPS");
  }

  this.loading = false;
}

}
