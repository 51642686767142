import { Component, inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { RouterLink, RouterLinkActive, Router, ActivatedRoute } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../services/auth.service';
import { DbService } from '../services/db.service';
import { INIT_LESSON } from '../shared/lesson';
import { StatsService } from '../services/stats.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    RouterLink,
    RouterLinkActive],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  db = inject(DbService);
  stats = inject(StatsService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  document = inject(DOCUMENT);

  isLoggedIn;

  constructor(authService: AuthService) {
    this.isLoggedIn = authService.isLoggedIn;
  }


  saveAndGo(location: string) {
    this.stats.saveStats(true);
    this.db.lesson.set(INIT_LESSON);
    this.router.navigateByUrl(location);

  }

  reset() {
    this.db.lesson.set(INIT_LESSON);
  }

  toggleMenu() {
    let el = document.getElementById("menu2");
    el?.style.setProperty('display', 'visible');

  }

}
