import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DbService } from '../services/db.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lul-file-uploader',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatProgressSpinnerModule, MatIconModule],
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})


export class FileUploaderComponent {


  http = inject(HttpClient);
  db = inject(DbService);

  errorMessage: any = null;
  successMessage = "";
  fileName = "";
  loading = false;
  selectedFile: File | undefined = undefined;
  filePath = this.db.filePath;
  audioPath = this.db.audioPath;

  form = new FormGroup({
    file: new FormControl()
  });

  constructor() { }

  onFileSelected(event: any) {
    this.successMessage = "";
    this.errorMessage = null;
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
      // this.fileName = this.selectedFile?.name ?? "No File Selected";
      if (this.selectedFile?.name) {
        this.fileName = this.selectedFile.name;
        this.onSubmit();
      } else this.fileName = "No File Selected";

    };
  }

  onSubmit() {
    this.loading = true;
    const formData = new FormData();
    formData.append('file', this.selectedFile!);

    this.http.post('https://blog.teacherjake.com/upload', formData)
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          console.log(response.path);
          if (response.path.endsWith('.mp3')) {
            this.audioPath.set(response.path || '');
          } else {
            this.filePath.set(response.path || '');
          }
          this.selectedFile = undefined;
          this.form.reset();
          this.successMessage = "File Upload Successful";
          this.fileName = "";

        },
        error: (error) => {
          this.loading = false;
          console.error('Error Uploading File', error);
          this.errorMessage = error;

        }
      });
  }

  clearMessages() {
    this.errorMessage = null;
    this.successMessage = "";
    this.fileName = "";
    this.loading = false;
  }

}
