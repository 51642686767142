import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { DbService } from './db.service';
import { Creature, CreatureCollection, CreatureFrequency, INIT_CREATURE } from '../shared/creature';
import { HttpClient } from '@angular/common/http';
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from '@angular/fire/firestore';
import { StatsService } from './stats.service';


@Injectable({
  providedIn: 'root'
})



export class CreatureService {


  db = inject(DbService);
  statsService = inject(StatsService);
  foundCreature = this.db.foundCreature;
  http = inject(HttpClient);

  creatures: CreatureCollection = { common: [], uncommon: [], rare: [], legendary: [] };
  creature = this.db.creature;
  creaturesCollected = computed(() => this.db.userProfile().creatures);
  userProfile = this.db.userProfile;
  userEmail = this.db.userEmail;
  openCreatureDialog = signal(false);
  #creaturesRef = this.db.creaturesRef;



  constructor() {

    this.getCreaturesJSON();

    effect((() => {
      this.foundCreature();
      console.log("found creature value", this.foundCreature());
      if (this.foundCreature() === true) this.assignCreature().then(creature =>
        this.creature = creature);
      else return;

    }), { allowSignalWrites: true });



  }

  async assignCreature() {
    const creatureType = this.creatureType();
    this.creature = this.creatures[creatureType][Math.floor(Math.random() * this.creatures[creatureType].length)];
    await this.saveCreatureToProfile({ ...this.creature }).then(() =>
      this.openCreatureDialog.set(true)
    );
    this.foundCreature.set(false);

    console.log("creature assigned", this.creature);
    return this.creature;
  }




  creatureType() {
    const roll = Math.floor(Math.random() * 1000);
    if (roll < 5) { return 'legendary' };
    if (roll < 50) { return 'rare' };
    if (roll < 250) { return 'uncommon' };
    if (roll < 1000) { return 'common' };
    return 'common';

  }

  getCreaturesJSON() {
    return this.http.get<CreatureCollection>('../../assets/creatures/creatures.json').subscribe((data) => {
      this.creatures = data;
      // console.log("creatures available", this.creatures);
    });
  }

  createDataSource(): Creature[] | null {
    try {
      const ELEMENT_DATA: Creature[] = [INIT_CREATURE];
      if (!this.creaturesCollected()) return ELEMENT_DATA;
      return this.creaturesCollected();

    } catch (error) {
      console.error('Error creating data source:', error);
      return null;
    }
  }

  matchCreature(id: string, frequency: CreatureFrequency) {
    try {
      const creatures = this.creatures || [];
      const creatureIndex = creatures[frequency].findIndex((element: Creature) => {
        return element.id === id;
      });
      this.creature = { ...creatures[frequency][creatureIndex] };
      return;
    } catch (error) {
      console.error('Error matching creature:', error);
      return null;
    }
  }

  simulateFind() {
    this.foundCreature.set(true);
    console.log("found creature value", this.foundCreature());
  }

  async saveCreatureToProfile(creature: Creature) {
    if (!creature.id) return;

    this.userProfile.update(profile => {
      let updatedProfile = {...profile};
      if (!updatedProfile.creatures) {
        updatedProfile.creatures = [];
      }
      let thisCreature = { ...creature };
      thisCreature.dateFound = new Date().toISOString();
      thisCreature.quantity = 1;

      updatedProfile.creatures.push({ ...thisCreature });
      let duplicateCreatures = updatedProfile.creatures.filter((c: Creature) => c.id === creature.id);
      while (duplicateCreatures.length > 1) {
        const count = duplicateCreatures.reduce((acc: number, c: Creature) => acc + c.quantity, 0);
        thisCreature.quantity = count;
        const newArray = updatedProfile.creatures.filter((c: Creature) => c.id !== creature.id);
        updatedProfile.creatures = [...newArray];
        updatedProfile.creatures.push({ ...thisCreature });
        duplicateCreatures = updatedProfile.creatures.filter((c: Creature) => c.id === creature.id);
      }
      return updatedProfile;
    });
    this.saveStats(true);
  }


  saveStats(toDB: boolean) {
    this.statsService.saveStats(toDB);
  }

  async addCreatureToList(creature: Creature) {
    const docRef = this.#creaturesRef;
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const continueAdd = confirm("Creature Exists: Do you want to replace it?");
      if (!continueAdd) return;
      const existingCreature = await docSnap.data()[creature.frequency].find((c: Creature) => c.id === creature.id);
      if (existingCreature) {
        updateDoc(docRef, { [creature.frequency]: arrayRemove(existingCreature) });
        updateDoc(docRef, { [creature.frequency]: arrayUnion(creature) });
        return;
      } else {
        updateDoc(docRef, { [creature.frequency]: arrayUnion(creature) });
      }
    }
  }



}