import { ChangeDetectorRef, Injectable, inject, signal } from '@angular/core';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class SpeakService {

  db = inject(DbService);

  cdRef: ChangeDetectorRef | undefined;
  rate = signal<number>(1);
  selectedLanguage = signal<string | undefined>(undefined);
  currentLanguage = this.db.lessonLanguage; //allows the user to override the default voice
  currentLinesRead = this.db.currentLinesRead;
  hasplayed = signal<boolean>(false);

  isPlaying = signal<boolean>(false);
  isPaused: boolean = false;

  foundCreature = this.db.foundCreature;


  constructor() {

  }


  setChangeDetector(cdr: ChangeDetectorRef) {
    this.cdRef = cdr;
  }

  pauseVoice() {
    if (!this.isPaused) {
      speechSynthesis.pause();
      this.isPlaying.set(false);
      this.isPaused = true;
    }
    else {
      speechSynthesis.resume();
      this.isPlaying.set(true);
      this.isPaused = false;
    }
  }

  readUtterance(line: string, points: number, lang?: string, rate?: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.hasplayed.set(true);
      if (this.isPlaying() && !this.isPaused) {
        speechSynthesis.cancel();
        this.isPlaying.set(false);
        reject('Speech was cancelled');
        return false;
      }
      if (this.isPaused) {
        speechSynthesis.resume();
        this.isPlaying.set(true);
        this.isPaused = false;
        reject('Speech was resumed');
        return;
      }
      else {
        this.isPlaying.set(true);

        if (lang) lang = this.selectedLanguage() || lang;
        if (!lang) lang = this.selectedLanguage() || document.documentElement.lang;
        console.log(lang);
        let utterance = new SpeechSynthesisUtterance(line);
        utterance.lang = lang;
        utterance.rate = rate || this.rate();
        window.speechSynthesis.speak(utterance);
        utterance.onend = () => {
          this.currentLinesRead.update(old => old + points)
          this.isPlaying.set(false);
          let x = Math.floor(Math.random() * (100 / points));
          if (x.toFixed(0) == '25') {
            console.log("found creature");
            this.foundCreature.set(true);
          }
          console.log("lines read", this.currentLinesRead());
          this.cdRef?.detectChanges();
          resolve(false);
        };
        return true;
      }
    });
  }

}
