export const environment = {
  firebase: {
    projectId: 'reading-tool',
    appId: '1:274566980640:web:98557afbc22a2223d28845',
    storageBucket: 'reading-tool.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyBg8-bTvtXfZOXOiYGctyC19mCtuRQZUX0',
    authDomain: 'reading-tool.firebaseapp.com',
    messagingSenderId: '274566980640',
    measurementId: 'G-MRDW5YTH7R',
  },
  useEmulators: false,
  production: false
  // localCache: `persistentLocalCache({ tabManager: persistentMultipleTabManager() })`
  
};