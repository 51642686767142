<ng-container>
  <section id="top" translate="no">
    <div class="rounded-box withShadow withGreen withMarginBottom" translate="yes" *ngIf="!(isLoggedIn | async)">
      <app-login></app-login>
    </div>
    <!-- <div class="container">
      <img [src]="lessonForm.value.imageUrl" width="100%" class="withShadow full-width-image">
    </div> -->
    <details class="blue withShadow withMarginBottom" [open]="isOpen">
      <summary class="spaced">CREATE / EDIT A LESSON 
        <button class="btn grey smaller" type="button" (click)="saveLesson()" [disabled]="!lessonForm.valid">COPY TO MY LESSONS</button></summary>
      <p>Usage: Paste or type in text that you would like help reading. Scroll down to listen to your text line-by-line
        or
        to see translations. Add additional line breaks to break up long sentences. Save
        text to study later or on another device.</p>
      <form [formGroup]="lessonForm">
        <app-warning-tts></app-warning-tts>

        <div class="button-row" *ngIf="this.userEmail() != 'none'">
          <button *ngIf="userEmail() === 'admin@teacherjake.com'" class="header-btn" type="button"
            (click)="addLesson('public')">ADD PUBLIC</button>
          <button *ngIf="userEmail() === 'admin@teacherjake.com'" class="header-btn" type="button"
            (click)="updateLesson('public')">UPDATE PUBLIC</button>
          <button class="header-btn" type="button" (click)="clearText()">CLEAR</button>
          <button class="header-btn" type="button" (click)="pasteText()">PASTE</button>
        </div>
        <mat-form-field color="primary">
          <textarea matInput #content title="form content" formControlName="content" required></textarea>
        </mat-form-field>
        <mat-form-field class="np" color="primary">
          <mat-label>Vocabulary: Ex. cat, hat, open</mat-label>
          <input matInput #tags formControlName="vocabulary" placeholder="Vocabulary" type="text">
        </mat-form-field>
        <span class="row">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput #title formControlName="title" placeholder="Title" type="text">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Language</mat-label>
            <mat-select #languageSelect formControlName="language" placeholder="English">
              <mat-option translate="no" *ngFor="let language of languages" [value]="language.name">
                {{language.name}} - {{language.code}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Other Tags</mat-label>
            <input matInput #tags formControlName="tags" placeholder="A1, story" type="text">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Image URL</mat-label>
            <input matInput #imageURL formControlName="imageUrl" placeholder="Image Location (Optional)" type="text">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Audio URL</mat-label>
            <input matInput #audioURL formControlName="audioUrl" placeholder="Audio File (Optional)" type="text">
          </mat-form-field>
        </span>
      </form>
      <section class="blue neg-top">
        @if (userEmail() === 'admin@teacherjake.com') {
          <lul-file-uploader></lul-file-uploader>
        }
        @if (this.lesson().ownerID != 'public') {
          <div class="button-row" *ngIf="this.userEmail() != 'none'">
            <button class="header-btn mt-5" type="button" (click)="saveLesson()" [disabled]="!lessonForm.valid">SAVE</button>
          </div>
        }
      </section>
    </details>

    <!-- Controls and Line Reader -->
    <app-line-reader></app-line-reader>
    <router-outlet></router-outlet>
  </section>
  <!-- <img property=”og:image” src="../../assets/icons/icon-512x512.png" alt="logo" routerLink="/home"> -->
</ng-container>