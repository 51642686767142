export interface Creature {
    id: string;
    name: string;
    image: string;
    video: string;
    frequency: string;
    description: string;
    type: string;
    tags: string[];
    quantity: number;
    dateFound?: string;
}


export interface CreatureCollection {
    common: Creature[];
    uncommon: Creature[];
    rare: Creature[];
    legendary: Creature[];
}

export type CreatureFrequency = 'common' | 'uncommon' | 'rare' | 'legendary';


export const INIT_CREATURE: Creature = {
    id: '',
    name: '',
    image: '',
    video: '',
    frequency: '',
    description: '',
    type: '',
    tags: [],
    quantity: 0
};

