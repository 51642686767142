import { Component, Input, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as marked from 'marked';
import { DbService } from '../services/db.service';

@Component({
  selector: 'app-full-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './full-text.component.html',
  styleUrls: ['./full-text.component.scss']
})
export class FullTextComponent {

  @Input() translatedDiv: string = "fullDiv";

  db = inject(DbService);
  lesson = this.db.lesson;
  convertedMarkdown: string = ''; 

constructor() {

  effect(() => {
    this.lesson();
    this.convertMarkdown(this.lesson().content);
    
  });

}

convertMarkdown(content: string) {
  this.convertedMarkdown = marked.parse(content);
} 


}
