<details class="settings">
    <summary>Settings</summary>
    <form [formGroup]="form">
        <div class="flex-row spaced">
            <div class="grid-box select">
                <select #language title="select a TTS Voice" name="selectedLanguage" formControlName="selectedLanguage">
                    <option value=default selected>Default</option>
                    <option value="ar-SA">Arabic</option>
                    <option value="zh-CN">Chinese</option>
                    <option value="nl-NL">Dutch</option>
                    <option value="en-CA">English</option>
                    <option value="en-US">English 2</option>
                    <option value="fil-PH">Filipino</option>
                    <option value="fr-FR">French</option>
                    <option value="fr-CA">French 2</option>
                    <option value="de-DE">German</option>
                    <option value="de-AT">German 2</option>
                    <option value="hi-IN">Hindi</option>
                    <option value="it-IT">Italian</option>
                    <option value="ja-JP">Japanese</option>
                    <option value="ko-KR">Korean</option>
                    <option value="pt-PT">Portuguese</option>
                    <option value="ru-RU">Russian</option>
                    <option value="es-ES">Spanish (Spain)</option>
                    <option value="es-MX">Spanish (Mexico)</option>
                    <option value="es-AR">Spanish 3</option>
                    <option value="th-TH">Thai</option>
                    <option value="uk-UA">Ukrainian</option>
                </select>
                <label class="main-label" translate="yes">Speech Voice</label>
            </div>


            <div #rate class="radio-group" translate="yes">
                <div>
                    <span>
                        <label>
                            <input type="radio" name="rate" formControlName="rate" [value]=0.7 /> Slow
                        </label>
                    </span>
                    <span>
                        <label>
                            <input type="radio" name="rate" formControlName="rate" [value]=1 /> Normal
                        </label>
                    </span>
                    <span>
                        <label>
                            <input type="radio" name="rate" formControlName="rate" [value]=1.3 /> Fast
                        </label>
                    </span>
                </div>
                <label class="main-label">Voice Speed:</label>
            </div>
            <div class="grid-box select">
                <select formControlName="theme" name="theme" title="Color Theme">
                    <option value='white'>White</option>
                    <option value='yellow'>Yellow</option>
                    <option value='black'>Black</option>
                    <option value='green'>Green</option>
                    <option value='blue'>Blue</option>
                    <option value='default'>Default</option>
                </select>
                <label class="main-label" for="theme">Theme</label>
            </div>
            <div class="grid-box select">
                <select formControlName="fsize" name="fsize" title="Color Theme">
                    <option value='small'>XS</option>
                    <option value='medium'>Small</option>
                    <option value='large'>Medium</option>
                    <option value='larger'>Large</option>
                    <option value='x-large'>XL</option>
                    <option value='xx-large'>XXL</option>
                </select>
                <label class="main-label" for="theme">Size</label>
            </div>
        </div>
    </form>
    <div class="controls center" id="lbl-anchor">
        <label id="google_translate_element">Language for Transations</label>
        <div id="google_translate_element"></div>
        <sub translate="yes">If available, you can use your browser's built in translator instead of selecting a
            language.
        </sub>
    </div>
</details>