<form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col">
  <input type="file" id="file" formControlName="file" (change)="onFileSelected($event)" class="file-input" #fileUpload>
  <div class="file-upload">
    <button type="button" (click)="fileUpload.click()"><mat-icon fontIcon="attach_file"></mat-icon> (jpeg/png)</button>
    {{fileName || "Choose a file."}}
  </div>
  @if (errorMessage) {
    <div class="warning">
      <br>
      {{errorMessage.error.message}}
      <button type="button" class="warning" (click)="clearMessages()">X</button>
    </div>
  }
  @if (successMessage) {
    {{successMessage}} 👍
    <p>{{filePath()}}</p>
  }
  @if (loading) { <mat-spinner></mat-spinner> } 
  </form>