import { Component, Input, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpeakService } from '../services/speak.service';
import { DbService } from '../services/db.service';

import { VocabMatchComponent } from '../vocab-match/vocab-match.component';

@Component({
  selector: 'app-vocab-list',
  standalone: true,
  imports: [CommonModule, VocabMatchComponent],
  templateUrl: './vocab-list.component.html',
  styleUrls: ['./vocab-list.component.scss']
})
export class VocabListComponent {

  speakService = inject(SpeakService);
  db = inject(DbService);

  lesson = this.db.lesson;
  
  @Input() showTranslation = false;

  vocabList = this.db.lesson || [];
  playGame: boolean = false;

  constructor() {

    effect(() => {
      this.vocabList();
      this.playGame = false;
    })
  }

  playAgain() {
    console.log("play again", this.showTranslation);
    this.playGame = false;
    setTimeout(() => {
      this.playGame = true;
    }, 100);

  }



}

