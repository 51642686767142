import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { ReadingToolComponent } from './reading-tool/reading-tool.component';

export const routes: Routes = [
    { path: "", redirectTo: 'home', pathMatch: 'full' },
    { path: "home", component: HomeComponent },
    { path: "creature", loadComponent: () => import('./creature-maker/creature-maker.component').then(m => m.CreatureMakerComponent) },
    {
        path: "english/:id", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/english/english.component').then(m => m.EnglishComponent) }
        ]
    },
    {
        path: "english", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/english/english.component').then(m => m.EnglishComponent) }
        ]
    },
    {
        path: "user/:id", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/user/user.component').then(m => m.UserComponent) }
        ]
    },
    {
        path: "user", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/user/user.component').then(m => m.UserComponent) }
        ]
    },
    {
        path: "french/:id", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/french/french.component').then(m => m.FrenchComponent) }
        ]
    },
    {
        path: "french", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/french/french.component').then(m => m.FrenchComponent) }
        ]
    },
    {
        path: "spanish/:id", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/spanish/spanish.component').then(m => m.SpanishComponent) }
        ]
    },
    {
        path: "spanish", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/spanish/spanish.component').then(m => m.SpanishComponent) }
        ]
    },
    {
        path: "german/:id", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/german/german.component').then(m => m.GermanComponent) }
        ]
    },
    {
        path: "german", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/german/german.component').then(m => m.GermanComponent) }
        ]
    },
    {
        path: "thai/:id", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/thai/thai.component').then(m => m.ThaiComponent) }
        ]
    },
    {
        path: "thai", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/thai/thai.component').then(m => m.ThaiComponent) }
        ]
    },
    {
        path: "arabic/:id", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/arabic/arabic.component').then(m => m.ArabicComponent) }
        ]
    },
    {
        path: "arabic", component: ReadingToolComponent, children: [
            { path: "", loadComponent: () => import('./languages/arabic/arabic.component').then(m => m.ArabicComponent) }
        ]
    },
    { path: "read/:id", redirectTo: 'english/:id', pathMatch: 'full' },
    { path: "auth", component: LoginComponent },
    { path: "profile", loadComponent: () => import('./profile/profile.component').then(m => m.ProfileComponent) },
    { path: "**", redirectTo: 'home', pathMatch: 'full' }

];