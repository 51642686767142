<article id="lbl-top" translate="no" [lang]="this.lesson().languageCode">
    <span class="line-reader">

        <!-- Setting Box     -->
        <div class="withShadow withMarginBottom bg1 box"
            [ngStyle]="{'background-color': backgroundColor, 'color': textColor}">
            <app-settings></app-settings>
        </div>
        <!-- Full Text Box -->
        @if (lesson().content) {
        <details class="withShadow withMarginBottom box" id="detailsFullText"
            [ngStyle]="{'background-color': backgroundColor, 'color': textColor}" open>
            <div class="flex-row">
                <button class="withImage" type="button" [lang]="lesson().languageCode"
                    (click)="speakService.readUtterance(fulltext, listeningPoints, speakService.currentLanguage())">
                    <span translate="yes">
                        Listen
                        <img class="symbol" alt="headphones" src="../../assets/images/headphones.svg" />
                    </span>
                </button>
            </div>
            <summary>Full Text</summary>
            <cdk-virtual-scroll-viewport itemSize="16" class="lbl-viewport">
                <app-full-text #fullTextComponent></app-full-text>
            </cdk-virtual-scroll-viewport>
        </details>
        }
        <!-- Full Text Translation Box -->
        @if (lesson().content) {
        <details id="detailsFullTranslation" class="withShadow withMarginBottom box"
            [ngStyle]="{'background-color': backgroundColor, 'color': textColor}">
            <summary (click)="toggleTranslation('detailsFullTranslation')">Translation</summary>
            @if (showTranslation) {
            <div class="flex-row">
                <button class="withImage" type="button" [lang]="lesson().languageCode"
                    (click)="speakService.readUtterance(getTranslatedText(), listeningPoints, getLang())">
                    <span translate="yes">
                        Listen
                        <img class="symbol" alt="headphones" src="../../assets/images/headphones.svg" />
                    </span>
                </button>
            </div>
            <cdk-virtual-scroll-viewport itemSize="50" class="lbl-viewport">
                <app-full-text translate="yes" translatedDiv="translatedText"></app-full-text>
            </cdk-virtual-scroll-viewport>
            <hr>
            <sub>Choose your language under "Settings" if the text is not being translated automatically.</sub>
            }
        </details>
        }
        <!-- Vocabulary List -->
        @if (lesson().vocabulary) {
        <ng-container>
            <details id="vocabListDetails" class="withShadow withMarginBottom box"
                [ngStyle]="{'background-color': backgroundColor, 'color': textColor}">
                <summary>Vocabulary List</summary>
                <div class="center">
                    <button class="withImage" type="button" (click)="toggleTranslation('vocabListDetails')">
                        Translations (on/off)</button>
                </div>
                <app-vocab-list [showTranslation]="showTranslation"></app-vocab-list>
            </details>
        </ng-container>
        }
        @if (lessonLineArray) {
        <!-- Line-By-Line Box -->
        <ng-container>

            <details class="withShadow withMarginBottom box"
                [ngStyle]="{'background-color': backgroundColor, 'color': textColor}" id="detailsLBL">
                <div class="flex-row">
                    <button class="withImage" type="button" (click)="toggleTranslation('detailsLBL')">
                        Translations (on/off)
                    </button>
                    <button class="withImage" type="button" [lang]="lesson().languageCode"
                        (click)="speakService.readUtterance(fulltext, listeningPoints, speakService.currentLanguage())">
                        <span translate="yes">
                            Listen
                            <img class="symbol" alt="headphones" src="../../assets/images/headphones.svg" />
                        </span>
                    </button>
                </div>
                <summary>Line-By-Line
                </summary>
                <div #lblList>
                    @defer (on viewport(lblList)) {
                    @for (line of lessonLineArray; track line.id; let i = $index;) {

                    <div class="lesson">
                        <div class="line lbl-item" *ngIf="line.text.length >0">

                            <div class="original" [lang]="lesson().languageCode" translate="no">
                                <div class="w90" (click)="toggleVisibility(i)">
                                    {{line.text}}
                                </div>
                                <img class="symbol" alt="headphones" src="../../assets/images/headphones.svg"
                                    (click)="speakService.readUtterance(line.text, 1, speakService.currentLanguage())">
                            </div>
                            @if (showTranslation || isVisible[i] || renderedOnce) {
                            <span (click)="readThis($event)" [lang]="lesson().languageCode" class="translated hidden"
                                [ngClass]="{'visible': showTranslation || isVisible[i]}" translate="yes">
                                {{line.text}}
                            </span>
                            }
                        </div>
                    </div>
                    }
                    } @placeholder {<div>Loading...</div>}
                    <div class="flex-row withMarginTop ">
                        <button (click)="scrollto('detailsLBL')">Back to Top</button>
                        <!-- <div *ngIf='userEmail() === "admin@teacherjake.com"'>
                    <button type="button" class="green" (click)="cs.simulateFind()">Find Creature</button>
                    <button type="button" class="green" routerLink="/creature">Add Creatures</button>
                </div> -->
                    </div>
                    <hr>
                    <app-stats></app-stats>
                </div>

            </details>
        </ng-container>
        }
    </span>
</article>