<mat-card>
  <div *ngIf="isSignup">
    <h3>Create Account</h3>

    <button mat-stroked-button size="small" (click)="changeType('login')">
      Returning user?
    </button>
  </div>

  <div *ngIf="isLogin">
    <h3>Sign In</h3>
    <button size="small" mat-stroked-button  (click)="changeType('signup')">
      New user?
    </button>
  </div>

  <div *ngIf="isPasswordReset">
    <h3>Reset Password</h3>
    <button size="medium" mat-button (click)="changeType('login')">Back</button>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field [color]="email?.valid ? 'accent' : undefined">
      <mat-label>Email:</mat-label>
      <input matInput formControlName="email" type="email" placeholder="Email" autocomplete="off" required />

      <mat-error *ngIf="email?.invalid && email?.dirty">
        You must enter a valid email address
      </mat-error>
    </mat-form-field>
    <mat-form-field [color]="email?.valid ? 'accent' : undefined">
      <mat-label>Password:</mat-label>
      <input matInput formControlName="password" type="password" placeholder="Password" autocomplete="off" />

      <mat-error *ngIf="password?.invalid && password?.dirty">
        Password must be at least 6 characters long
      </mat-error>
    </mat-form-field>
    <mat-form-field [color]="passwordDoesMatch ? 'accent' : 'warn'" *ngIf="isSignup">
      <mat-label>Confirm Your Passoword:</mat-label>
      <input matInput formControlName="passwordConfirm" type="password" placeholder="Confirm password"
        autocomplete="off" />

      <mat-error *ngIf="passwordConfirm?.dirty && !passwordDoesMatch">
        Password does not match
      </mat-error>

    </mat-form-field>
    <mat-error *ngIf="(isError$ | async) as errorMessage" class="server-error">{{ errorMessage }}
      <button type="button" (click)="dismissError()" >Ok</button>
    </mat-error>
    <button *ngIf="!isPasswordReset" mat-raised-button color="primary" size="medium" type="submit"
      [disabled]="form.invalid || !passwordDoesMatch || loading">
      Submit
    </button>
    <button *ngIf="isPasswordReset" mat-stroked-button type="submit" [disabled]="loading">
      Send Reset Email
    </button>
    <ng-container *ngIf="serverMessage && isPasswordReset">{{serverMessage}}</ng-container>
    <button type="submit" mat-stroked-button *ngIf="isLogin && !isPasswordReset" (click)="changeType('reset')">
      Forgot password?
    </button>
  </form>

</mat-card>