import { Component, OnInit, Renderer2, inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { SpeakService } from '../services/speak.service';
import { DbService } from '../services/db.service';


@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  db = inject(DbService);
  renderer = inject(Renderer2);
  document = inject(DOCUMENT);

  rate: number = 1;
  selectedLanguage: string | undefined = undefined;
  theme: string = "default";
  themeColors = this.db.themeColors;


  form = this.fb.group({
    rate: new FormControl(this.rate),
    selectedLanguage: new FormControl('default'),
    theme: new FormControl(this.theme),
    fsize: new FormControl('x-large')
  });


  constructor(private fb: FormBuilder, private speakService: SpeakService) {
    this.document.body.style.setProperty('font-size', 'x-large');
    this.form.valueChanges.subscribe((value) => {
      this.speakService.selectedLanguage.set(value.selectedLanguage || undefined);
      this.speakService.rate.set(value.rate || 1);
      this.setBackGroundColor(value.theme || 'default');
      this.document.body.style.setProperty('font-size', value.fsize || 'larger');
      console.log(value);
    });
  }

  ngOnInit() {
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    this.renderer.appendChild(this.document.body, script);

  }


  setBackGroundColor(color: string) {
    switch (color) {
      case "yellow": {
        this.themeColors.set(["lightyellow", "black"]);
        break;
      }
      case "blue": {
        this.themeColors.set(["#bbd5ff", "black"]);
        break;
      }
      case "green": {
        this.themeColors.set(['#dcf3dc', "black"]);
        break;
      }
      case "white": {
        this.themeColors.set(["white", "black"]);
        break;
      }
      case "black": {
        this.themeColors.set(["black", "white"]);
        break;
      }
      case "default": {
        this.themeColors.set(["wheat", "black"]);
        break;
      }
      default: {
        this.themeColors.set(["default", "default"]);
        break;
      }
    }
  }







}
