import { Component, effect } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DOCUMENT } from '@angular/common';
import { splitByLineBreaks, addLineBreaks, removeLineBreaks } from '../shared/utils';
import { SpeakService } from '../services/speak.service';
import { Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { VocabListComponent } from '../vocab-list/vocab-list.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { inject } from '@angular/core';
import { DialogCreatureComponent } from '../dialog-creature/dialog-creature.component';
import { CreatureService } from '../services/creature-service';
import { INIT_LESSON } from '../shared/lesson';
import { DbService } from '../services/db.service';
import { RouterLink } from '@angular/router';
import { StatsService } from '../services/stats.service';
import { SettingsComponent } from '../settings/settings.component';
import { StatsComponent } from '../stats/stats.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FullTextComponent } from '../full-text/full-text.component';
import { LessonLineArray } from '../shared/lesson-line-array';

// var window: any;


@Component({
  selector: 'app-line-reader',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule, MatDialogModule,
    VocabListComponent, RouterLink, StatsComponent, SettingsComponent,
    FullTextComponent, ScrollingModule
  ],
  templateUrl: './line-reader.component.html',
  styleUrls: ['./line-reader.component.scss']
})
export class LineReaderComponent {


  cs = inject(CreatureService);
  dialog = inject(MatDialog);
  db = inject(DbService);
  speakService = inject(SpeakService);
  statsService = inject(StatsService);
  document = inject(DOCUMENT);
  // renderer = inject(Renderer2);
  // document = inject(DOCUMENT);


  @Input() originalText: string = ""; //deprecated for signals
  lesson = this.db.lesson;
  listeningPoints = 0;
  userEmail = this.cs.userEmail;
  openCreatureDialog = this.cs.openCreatureDialog;
  dialogOpen = false;

  themeColors = this.db.themeColors;
  backgroundColor = this.themeColors()[0];
  textColor = this.themeColors()[1];
  isVisible: boolean[] = [];

  lessonLineArray: LessonLineArray[] = [];
  translatedLine: string = "";
  renderedOnce = false;
  fulltext: string = "";
  translatedText: string = "";
  showTranslation = false;
  lessonId = "";
  speaking = this.speakService.isPlaying;
  vocabListPresent = [''];

  constructor() {

    effect(() => {
      this.lesson();
      if (this.lesson().id !== this.lessonId) {
        this.loadLines();
        this.scrollto('banner-image');
        this.listeningPoints = this.lesson().content.length / 100;
        let el: HTMLElement | null = this.document.querySelector("html");
        if (el) {
          el.lang = this.lesson().languageCode || 'en';
        }
        if (this.lesson().vocabulary !== undefined) { this.vocabListPresent = this.lesson().vocabulary || ['']}
      }
    });

    effect(() => {
      this.themeColors();
      this.backgroundColor = this.themeColors()[0];
      this.textColor = this.themeColors()[1];
    });

    effect(() => {
      this.openCreatureDialog();
      if (this.openCreatureDialog() === true) {
        this.foundCreatureModal();
        this.openCreatureDialog.set(false);
      }
      else return;
    }, { allowSignalWrites: true });

  };

  loadLines() {
    this.renderedOnce = false;
    let lblText: string = addLineBreaks(this.lesson().content);
    let newArray = splitByLineBreaks(lblText);
    this.lessonLineArray = [...newArray];
    this.isVisible = new Array(this.lessonLineArray.length).fill(false);
    this.fulltext = removeLineBreaks(this.lesson().content);
    this.showTranslation = false;
    this.lessonId = this.lesson().id;
    if (this.lesson().id == INIT_LESSON.id) return;
  }

  toggleTranslation(me: string) {
    this.showTranslation = !this.showTranslation;
    this.isVisible = new Array(this.lessonLineArray.length).fill(false);
    

    const lbl = this.document.getElementById("lbl");
    const vocabList = this.document.getElementById("vocabListDetails");
    const fullTranslationDiv = this.document.getElementById("detailsFullTranslation");
    
    if (this.showTranslation) {
      lbl?.removeAttribute("open");
      vocabList?.removeAttribute("open");
      fullTranslationDiv?.removeAttribute("open");
    }
    setTimeout(() => {
      fullTranslationDiv?.setAttribute("open", "true");
      lbl?.setAttribute("open", "true");
      vocabList?.setAttribute("open", "true");
      setTimeout(() => this.scrollto(me), 300);
    }, 300);
    this.renderedOnce = true;
  }

  toggleVisibility(i: number) {
    this.isVisible[i] = !this.isVisible[i];
  }

  trackByFunction(_index: any, item: any) {
    return item.id;
  }


  scrollto(me:string) {
    let el: HTMLElement | null = this.document.getElementById(me);
    if (el) {
      el.scrollIntoView({ behavior: "smooth"});
    }
  }

  foundCreatureModal() {
    this.dialog.open(DialogCreatureComponent);
  }

  readThis($event: any) {
    let docLAng = this.document.querySelector('html')?.lang || 'en-CA';
    let spanText = $event.target as HTMLSpanElement;
    this.speakService.readUtterance(spanText.textContent || "", 1, this.speakService.selectedLanguage() || docLAng ); 
  }

  getLang() {
    let docLang = this.document.querySelector('html')?.lang || 'en-CA';
    return docLang;
  }

  getTranslatedText() {
    this.translatedText = this.document.getElementById("translatedText")?.innerText || "none";  
    return this.translatedText;
  }


}