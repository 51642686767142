import { Component, Input, Signal, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { SpeakService } from '../services/speak.service';
import { DbService } from '../services/db.service';
import { AuthService } from '../services/auth.service';
import { languages } from '../shared/utils';
import { WarningTtsComponent } from '../warning/warning-tts.component';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { LoginComponent } from '../auth/login/login.component';
import { StatsComponent } from '../stats/stats.component';
import { LineReaderComponent } from '../line-reader/line-reader.component';
import { Lesson } from '../shared/lesson';
import { FileUploaderComponent } from "../file-uploader/file-uploader.component";
import { Timestamp } from '@angular/fire/firestore';
import { timestamp } from 'rxjs';

@Component({
    selector: 'app-reading-tool',
    standalone: true,
    templateUrl: './reading-tool.component.html',
    styleUrls: ['./reading-tool.component.scss'],
    imports: [CommonModule, WarningTtsComponent,
        ReactiveFormsModule, RouterOutlet, RouterLink,
        LineReaderComponent, StatsComponent,
        MatSelectModule, LoginComponent, MatFormFieldModule, MatInputModule, MatTabsModule, FileUploaderComponent]
})
export class ReadingToolComponent {

  @Input() language = "";
  @Input() id: string = "";
  @Input() isOpen = false;

  route = inject(ActivatedRoute);

  userEmail = this.db.userEmail;
  isLoggedIn = this.authService.isLoggedIn;
  searchId: string = "";
  // lessonType: string = "user";

  lesson: Signal<Lesson> = this.db.lesson;
  filePath = this.db.filePath;
  audioPath = this.db.audioPath;

  languages = languages //imported from utils
  lessonLanguage = this.db.lessonLanguage;

  lessonForm = this.fb.group({
    id: this.fb.control(''),
    title: this.fb.control(''),
    content: this.fb.control('default content', Validators.required),
    vocabulary: this.fb.control(''),
    language: this.fb.control('', Validators.required),
    tags: this.fb.control(''),
    date: this.fb.control(Timestamp.fromDate(new Date)),
    ownerID: this.fb.control(''),
    imageUrl: this.fb.control(this.filePath()),
    audioUrl: this.fb.control(this.audioPath())
  });


  constructor(public fb: FormBuilder,
    private db: DbService,
    public speakService: SpeakService,
    private authService: AuthService) {

    effect(() => {
      this.userEmail();
      this.lessonForm.controls.ownerID.patchValue(this.userEmail());
    });

    effect(() => {
      this.lesson();
      this.editLesson(this.lesson());
    });

    effect(() => {
      this.filePath();
      this.lessonForm.controls.imageUrl.patchValue(this.filePath());
    })
    

  };



  ngOnInit() {
    console.log(this.id, this.language);
    // if(this.language) this.lessonType = this.language;
  }

  ngOnChanges() {

  }




  //loads lesson for editing called by signal effect
  editLesson(lesson: Lesson) {
    if (lesson.title === undefined) lesson.title = lesson.content.slice(0, 30) + "...";

    const tagsString = lesson.tags?.join(", ") || "";
    const vocabString = lesson.vocabulary?.join(", ") || "";
    let tStamp = null;

    this.lessonForm.patchValue({

      id: lesson.id,
      title: lesson.title,
      content: lesson.content,
      vocabulary: vocabString,
      language: lesson.language,
      date: lesson.date,
      tags: tagsString,
      ownerID: lesson.ownerID,
      imageUrl: lesson.imageUrl,
      audioUrl: lesson.audioUrl
    });
  };


  copyToMyLessons() {
    this.addLesson();
  }

  saveLesson() {
    if (this.lessonForm.value.id !== 'initial') this.updateLesson();
    else this.addLesson();
  }


  async addLesson(lessontype?: string) {
    let tagsInput = this.lessonForm.value.tags;
    const tags = tagsInput?.split(",").map(tag => tag.trim());
    let vocabInput = this.lessonForm.value.vocabulary;
    const vocab = vocabInput?.split(",").map(word => word.trim());


    const lesson: Lesson = {
      id: "not set",
      title: this.lessonForm.value.title || "none",
      content: this.lessonForm.value.content || "default content",
      vocabulary: vocab || [],
      language: this.lessonForm.value.language || "English",
      tags: tags || ["none"],
      date: this.lessonForm.value.date || Timestamp.fromDate(new Date()),
      ownerID: lessontype || this.db.userEmail(),
      imageUrl: this.lessonForm.value.imageUrl || "",
      audioUrl: this.lessonForm.value.audioUrl || ""
    }
    if (lesson.title === "none") lesson.title = lesson.content.slice(0, 30) + "...";
    const newId = await this.db.addLesson(lesson);
    this.lessonForm.controls.id.patchValue(newId);
    let publicLesson = false;
    (lesson.ownerID == 'public') ? publicLesson = true : publicLesson = false
    this.db.pushLesson(lesson, publicLesson);

  }
  updateLesson(lessontype?: string) {
    let tagsInput = this.lessonForm.value.tags;
    const tags = tagsInput?.split(",").map(tag => tag.trim());
    let vocabInput = this.lessonForm.value.vocabulary;
    const vocab = vocabInput?.split(",").map(word => word.trim());

    const lesson: Lesson = {
      id: this.lessonForm.value.id || "not set",
      title: this.lessonForm.value.title || "none",
      content: this.lessonForm.value.content || "default content",
      vocabulary: vocab || [],
      language: this.lessonForm.value.language || "English",
      tags: tags || ["none"],
      date: this.lessonForm.value.date || Timestamp.fromDate(new Date()),
      ownerID: lessontype || this.db.userEmail() || "not set",
      imageUrl: this.lessonForm.value.imageUrl || '',
      audioUrl: this.lessonForm.value.audioUrl || ''
    }
    if (lesson.title === "none") lesson.title = lesson.content.slice(0, 15) + "...";
    return this.db.updateLesson(lesson);
  }


  clearText() {
    this.lessonForm.reset();
    this.lessonForm.controls.id.patchValue('initial');
    this.setEmail(this.userEmail());
  }

  setEmail(email: string) {
    this.lessonForm.controls.ownerID.patchValue(email);
  }

  pasteText() {
    this.clearText();
    const text = navigator.clipboard.readText(
    ).then(clipText => {
      this.lessonForm.patchValue({
        content: clipText
      });
    });
  }


}



