import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import { Auth, authState, GoogleAuthProvider, 
  signInWithPopup, signInWithEmailAndPassword, 
  sendPasswordResetEmail, createUserWithEmailAndPassword, UserCredential,
   user, 
   getAdditionalUserInfo} from '@angular/fire/auth';
import { distinct, distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  authState$;
  user$;

  private userEmail$;
  private errorSubject = new Subject<string | null>();
  isNewUser = signal(false);

  router = inject(Router);
  auth = inject(Auth);

  constructor() {
    this.authState$ = authState(this.auth).pipe(
      shareReplay(1)
    );
    this.user$ = user(this.auth).pipe(
      distinctUntilChanged(),
      shareReplay(1));
    
    this.userEmail$ = this.user$.pipe(
      map(aUser => {
        if (aUser && aUser.email) return aUser.email
        else return "";
      }),
      shareReplay(1));
  }

  signInGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(this.auth, provider).then(
      (userCredential: UserCredential) => {
        const additionalUserInfo = getAdditionalUserInfo(userCredential);
        if (additionalUserInfo && additionalUserInfo.isNewUser) {
          this.isNewUser.set(true);
        }
        // this.router.navigate(['/home']);
      }
    ).catch(error => {
      this.errorSubject.next(error.message);
    });
  }

  signInEmail(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password).catch(error => {
      this.errorSubject.next(error.message);
      
    });
  }

  createUserWithEmail(email: string, password: string) {
    createUserWithEmailAndPassword(this.auth, email, password).then(
      (userCredential: UserCredential) => {
        const additionalUserInfo = getAdditionalUserInfo(userCredential);
        if (additionalUserInfo && additionalUserInfo.isNewUser) {
          this.isNewUser.set(true);
        }
      }
    ).catch(error => {
      this.errorSubject.next(error.message);
    });
  }

  sendReset(email: string) {
    sendPasswordResetEmail(this.auth, email).catch(error => {
      this.errorSubject.next(error.message);
    });
  }

  signOut() {
    this.auth.signOut();
  }

  getUser() {
    return this.user$;
  }

  clearError() {
    this.errorSubject.next(null);
  }

  get isLoggedIn() {
    return this.authState$;
  }

  get userEmail() {
    return this.userEmail$;
  }

  get error$() {
    return this.errorSubject.asObservable();
  }

  ngOnDestroy() {
  }
}
