import { Component, Input, OnDestroy, OnInit, Signal, computed, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, map } from 'rxjs';
import { DbService } from '../services/db.service';
import { SpeakService } from '../services/speak.service';
import { Lesson } from '../shared/lesson';

@Component({
  selector: 'app-vocab-match',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './vocab-match.component.html',
  styleUrls: ['./vocab-match.component.scss']
})
export class VocabMatchComponent {

  speakService = inject(SpeakService);
  db = inject(DbService);

  @Input() id: string = "";

  showTranslation = false;

  lesson = this.db.lesson;
  vocabList;
  completeList;
  shuffledList;

  sub = new Subscription();

  word1: string = "";
  word2: string = "";
  div1: HTMLDivElement | null = null;
  div2: HTMLDivElement | null = null;
  playing = true;

  constructor() {
    this.vocabList = computed(() => this.lesson().vocabulary || ["None"]);
    this.completeList = computed(() => this.vocabList().flatMap((word: string) => [[word, word]]));
    this.shuffledList = this.shuffle(this.completeList());

  }


  shuffle(array: string[][]): string[][] {
    let currentIndex = array.length, randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      // maxItems--;
    }


    let duplicatedList: string[][] = array.slice(0, 6);
    console.log("duplicatedList", duplicatedList);
    duplicatedList = duplicatedList.flatMap((word: string[]) => [[word[0], word[1], 'translated'], [word[0], word[1], 'original']]);
    console.log("duplicatedList", duplicatedList);

    return this.reshuffle([...duplicatedList]);
  }

  reshuffle(array: string[][]) {
    let currentIndex = array.length, randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return [...array];
  }

  clicked(word: string, event: any): boolean {
    const div: HTMLDivElement = event.target.closest('div');
    event.stopPropagation();
    const lang = div.lang || "en";
    console.log("clicked", lang);
    this.speakService.readUtterance(word, .1, lang);
      // if (div.classList.contains('translatedTile')) {
      // } else this.speakService.readUtterance(div.innerText, .1, lang);
    if (div.classList.contains('matched') || div.classList.contains('clicked')) {
      return false;
    }
    else {
      if (this.div1 === null) {
        this.div1 = div;
        this.div1.classList.add('clicked');
        this.word1 = word;
        return false;
      }
      if (this.div2 === null) {
        this.div2 = div;
        this.div2.classList.add('clicked');
        this.word2 = word;

      }
    }

    if ((this.div1 && this.div2) && (this.word1 === this.word2)) {
      this.div1.classList.add('matched');
      this.div2.classList.add('matched');
      this.div1 = null;
      this.div2 = null;
      this.word1 = "";
      this.word2 = "";
      return true;
    }
    setTimeout(() => {
      if (this.div1 && this.div2) {
        this.div1.classList.remove('clicked');
        this.div2.classList.remove('clicked');
        this.div1 = null;
        this.div2 = null;
        this.word1 = "";
        this.word2 = "";
      }
    }, 700);

    return false;
  }


  toggleTranslations() {
    this.showTranslation = !this.showTranslation;
    this.playing = false;
    this.shuffledList = this.shuffle(this.completeList());
    setTimeout(() => {
      this.playing = true;
    }, 200);
    setTimeout(() => {
      this.setTranslateAttribute(this.showTranslation);
    }, 500);
    console.log("showtranslation", this.showTranslation);
  }

  setTranslateAttribute(translate: boolean) {
    if (translate === true) {
      const els = document.querySelectorAll('.translatedTile');
      els.forEach((el: Element) => {
        if (el instanceof HTMLDivElement) {
          el.setAttribute('translate', 'yes');
          console.log("set translate to yes");
        }
      });
    }
    if (translate === false) {
      const els = document.querySelectorAll('.translatedTile');
      els.forEach((el: Element) => {
        if (el instanceof HTMLDivElement) {
          el.setAttribute('translate', 'no');
          console.log("set translate to no");
        }
      });
    }
  }


}
