<div translate="no" *ngIf="profile$ | async" class="stats">
    <p *ngIf="userEmail() !== 'none'">
        You have earned {{ unsavedLinesRead().toFixed(1)}}
        listening points since the last autosave. 
        <a (click)="openProfile()">(Total: {{(profileStats().linesRead).toFixed(1)}})</a>
        
        <button class="stats-button" type="button" (click)="saveStats()">Save & Exit</button>
    </p>
    <!-- Recenty Viewed: <span *ngFor="let item of history()" (click)="loadLesson(item)">{{item.title | slice:0:15}},
        </span> -->
</div>