import { Injectable, inject } from '@angular/core';
import { DbService } from './db.service';
import { updateDoc, getDoc } from '@angular/fire/firestore';
import { Profile, INIT_PROFILE } from '../shared/profile';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  db = inject(DbService);
  #userProfileRef;
  userProfile = this.db.userProfile;
  userEmail = this.db.userEmail;
  lessonHistory = this.db.lessonHistory;
  currentLinesRead = this.db.currentLinesRead;

  constructor() {

    this.#userProfileRef = this.db.userProfileRef;
  }

  async saveStats(toDB?: boolean): Promise<boolean> {
    let newSave = true;
    let sevenDayAverage = 0;
    let thirtyDayAverage = 0;
    let sevenDayCount = 0;
    let thirtyDayCount = 0;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate((sevenDaysAgo.getDate() - 7));
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate((thirtyDaysAgo.getDate() - 30));

    const currentLinesRead = await this.getStatsForSave();

    this.userProfile.update(profile => {
      let updatedProfile = { ...profile };
      updatedProfile.email = this.userEmail();
      updatedProfile.linesRead += currentLinesRead["linesRead"]
      updatedProfile.history = this.lessonHistory();
      const filteredArray = updatedProfile.dailyLinesRead.filter(item => new Date(item.date) >= thirtyDaysAgo);
      updatedProfile.dailyLinesRead = filteredArray as [{ date: string, linesRead: number }] || [{ date: today.toISOString(), linesRead: currentLinesRead["linesRead"] }];
      return updatedProfile;
    });

    for (let i = 0; i < this.userProfile().dailyLinesRead.length; i++) {
      const date = new Date(this.userProfile().dailyLinesRead[i].date);
      date.setHours(0, 0, 0, 0);
      if (date.toISOString() == today.toISOString()) {
        this.userProfile.update(profile => {
          let updatedProfile = { ...profile };
          updatedProfile.dailyLinesRead[i].linesRead += currentLinesRead["linesRead"];
          return updatedProfile;
        });
        newSave = false;
      }
    }
    if (newSave) {
      this.userProfile.update(profile => {
        let updatedProfile = { ...profile };
        updatedProfile.dailyLinesRead.push({ date: today.toISOString(), linesRead: currentLinesRead["linesRead"] })
        return updatedProfile;
      });

      while (this.userProfile().dailyLinesRead.length > 100) {
        this.userProfile.update(profile => {
          let updatedProfile = { ...profile };
          updatedProfile.dailyLinesRead.shift()
          return updatedProfile;
        });
      }
    }


    for (let i = 0; i < this.userProfile().dailyLinesRead.length; i++) {
      const date = new Date(this.userProfile().dailyLinesRead[i].date);
      date.setHours(0, 0, 0, 0);
      if (date >= sevenDaysAgo) {
        sevenDayAverage += this.userProfile().dailyLinesRead[i].linesRead;
        sevenDayCount++;
      }
      if (date >= thirtyDaysAgo) {
        thirtyDayAverage += this.userProfile().dailyLinesRead[i].linesRead;
        thirtyDayCount++;
      }
    }
    this.userProfile.update(profile => {
      let updatedProfile = { ...profile }
      updatedProfile.sevenDayAvg = sevenDayAverage / sevenDayCount;
      updatedProfile.thirtyDayAvg = thirtyDayAverage / thirtyDayCount;
      return updatedProfile;
    });
    const linesReadSinceSave = this.currentLinesRead();
    if (this.db.gotStats == true && (linesReadSinceSave > 100 || toDB === true)) {
      updateDoc(this.#userProfileRef, { ...this.userProfile() });
      // console.log("stats saved", this.userProfile().linesRead, this.userProfile().dailyLinesRead, this.userProfile().history);
      // localStorage.removeItem("linesReadSinceSave");
      return true;
    }
    return false;
  }

  async getStatsForSave() {
    if (this.db.gotStats == false) {
      await this.getStats();
      this.db.gotStats = true;
    }
    const count = this.currentLinesRead();
    this.currentLinesRead.set(0);
    return { linesRead: count };
  }

  async getStats() {
    try {
      this.#userProfileRef = this.db.userProfileRef;
      if (!this.#userProfileRef) return false;
      const docSnap = await getDoc(this.#userProfileRef).then((doc) => {
        const data = doc.data() as Profile;
        if (data) {
          this.userProfile.set({ ...INIT_PROFILE, ...data, email: this.userEmail() });
          this.db.gotStats = true;
          return true;
        } else {
          this.userProfile.set({ ...INIT_PROFILE });
          this.db.createUserProfile();
          this.db.gotStats = true;
          return true;
        }
      })
      return false;
    } catch (error) {
      console.error("Error getting stats: ", error);
      return false;
    }
  }
}
