import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { EmailComponent } from '../email/email.component';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { WarningTtsComponent } from 'src/app/warning/warning-tts.component';
import { DbService } from 'src/app/services/db.service';
import { INIT_PROFILE } from 'src/app/shared/profile';
import { StatsService } from 'src/app/services/stats.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, EmailComponent, MatButtonModule, 
    MatIconModule, WarningTtsComponent],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  auth = inject(AuthService);
  db = inject(DbService);
  router = inject(Router);
  statsService = inject(StatsService);

  userProfile = this.db.userProfile;
  useEmail = false;

  constructor() {}

  loginEmail()  {
    this.useEmail = !this.useEmail;
  }

  loginGoogle() {
    console.log("auth called from component");
    this.auth.signInGoogle();
  }
  

  async logout() {
    await this.statsService.saveStats(true).then((res) => {
      if (res === true) {
        // this.db.lessonHistory.set([]);
        this.db.userProfile.set(INIT_PROFILE);
        this.db.currentLinesRead.set(0);
        this.auth.signOut();
      }
    });
  }

}
