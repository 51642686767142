import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DbService } from '../services/db.service';
import { LoginComponent } from '../auth/login/login.component';
import { Router } from '@angular/router';
import { SpeakService } from '../services/speak.service';
import { StatsService } from '../services/stats.service';

@Component({
  selector: 'app-stats',
  standalone: true,
  imports: [CommonModule, LoginComponent],
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  speakService = inject(SpeakService);
  db = inject(DbService);
  statsService = inject(StatsService);
  router = inject(Router);
  changeDetectorRef = inject(ChangeDetectorRef);

  unsavedLinesRead = this.speakService.currentLinesRead;
  history = this.db.lessonHistory;
  profileStats = this.db.userProfile;
  userEmail = this.db.userEmail;
  hasPlayed = this.speakService.hasplayed;
  profile$ = this.db.profile$;
  constructor() {
    
  }

  ngOnInit(): void {
    this.statsService.getStats();
    this.speakService.setChangeDetector(this.changeDetectorRef);
    
  }

  saveStats() {
    this.statsService.saveStats(true);
    this.router.navigate(['/profile']);
  }

  openProfile() {
    this.statsService.saveStats(true);
    this.router.navigate(['/profile'], {fragment: 'stats'});
  }

}
