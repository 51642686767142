<ng-container *ngIf="vocabList().vocabulary as list">
    <p *ngIf="list.length < 1">NONE</p>
    <ng-container *ngIf="list.length > 0">
        <app-vocab-match *ngIf="playGame"></app-vocab-match>
        <div class="card-container" *ngIf="!playGame">
            <div *ngFor="let word of list">
                <div class="card" #fullText [lang]="lesson().languageCode" translate="no"
                    (click)="speakService.readUtterance(word, .1, speakService.currentLanguage())">
                    {{word}}
                    <!-- <img class="symbol sm" alt="headphones" src="../../assets/images/headphones.svg" /> -->
                </div>
                <div class="card translated" #translatedSpan [lang]="lesson().languageCode" translate="yes"
                    (click)="speakService.readUtterance(translatedSpan.textContent || '', .1)"
                    *ngIf="showTranslation == true">
                    {{word}}
                    <!-- <img class="symbol" alt="headphones" src="../../assets/images/headphones.svg" /> -->
                </div>
            </div>
        </div>
        <div class="flex-row withMarginTop">
            <button  #gameButton type="button" (click)="playGame = !playGame">{{!playGame ? "Play a Game" :
                "Study Vocabulary"}}</button>
                <button class="green" #againButton type="button" (click)="playAgain()" *ngIf="playGame">Play Again</button>
        </div>
    </ng-container>
</ng-container>